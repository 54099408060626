function nums(num) {
    num = num.toString().split('.')
    const arr = num[0].split('').reverse()
    let res = []
    for (let i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
            res.push(',')
        }
        res.push(arr[i])
    }
    res.reverse() 
    if(num[1]){
        // 如果有小数的话保留两位小数
        if(num[1].length >2){
            num[1] = num[1].slice(0,2)
        }
        res = res.join('').concat('.'+num[1])
    } else {
        res = res.join('')
    }
    return res
}

export default {
    nums
}

// export {
//     nums
// }

